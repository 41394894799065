import PullToRefresh from 'react-simple-pull-to-refresh';
import useBets from '../hooks/useBets';
import RaceInfo from '../components/RaceInfo';
import BetList from '../components/BetList';

const LiveBetting = () => {
  const { bets, race, fetchBets, getBalance } = useBets();

  const refreshData = () => {
    return Promise.all([fetchBets(), getBalance()]);
  };

  return (
    <>
      <PullToRefresh onRefresh={refreshData}>
        <>
          <RaceInfo race={race} />
          <BetList bets={bets} />
        </>
      </PullToRefresh>
    </>
  );
};

export default LiveBetting;
