import React from 'react';
import { Race } from '../interfaces';
import { Link } from 'react-router-dom';

interface RaceInfoProps {
  race?: Race;
}

const RaceInfo: React.FC<RaceInfoProps> = ({ race }) => {
  if (!race) return null;

  return (
    <div className="race">
      <h2>{race.trackName}</h2>
      <p>Race: {race.number}</p>
      <p className="mtp">{race.mtp.minutesToPost} MTP</p>
    </div>
  );
};

export default RaceInfo;
