import useBets from '../hooks/useBets';
import CircleWithNumber from './CircleWithNumber';
import RaceInfo from './RaceInfo';

const FairOdds = () => {
  const { race } = useBets();

  if (!race) return null;

  return (
    <>
      <RaceInfo race={race} />
      <div className="fair-odds mt-20">
        <h2 className="font-semibold">Fair Odds</h2>
        <div className="flex items-center justify-center mt-9">
          {race.fairOdds && race.fairOdds.length > 0 ? (
            <table className="table-auto font-semibold">
              {/* <thead>
                <tr>
                  <th className="px-4 py-2">#</th>
                  <th className="px-4 py-2">Runner Name</th>
                  <th className="px-4 py-2">Fair Odds</th>
                </tr>
              </thead> */}
              <tbody>
                {race.runners.map((horse: any) => (
                  <tr key={horse.programNumber}>
                    <td className="px-4 py-2">
                      <CircleWithNumber number={horse.programNumber} />
                    </td>
                    <td className="px-4 py-2">{horse.runnerName}</td>
                    <td className="px-4 py-2">{horse.fairOdds?.fair_odds ?? 'SCR'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-red-500 font-semibold">Fair Odds not available yet</p>
          )}
        </div>
      </div>
    </>
  );
};

export default FairOdds;
