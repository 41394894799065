import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import BetItem from './BetItem';
import { BetListProps } from '../interfaces';
import { HoldToConfirmButton } from './HoldToConfirmButton';
import useBets from '../hooks/useBets';
import LoadingSpinner from './LoadingSpinner';
import { currencyFormat } from './BalanceInfo';

const BetList: React.FC<BetListProps> = ({ bets }) => {
  const { placeBet, loading } = useBets();

  if (bets.length === 0 && !loading) {
    return <p className="noBetsP mt-20">More waigrs will be generated for you in a few minutes</p>;
  }

  return (
    <SwipeableViews className="" style={{ minHeight: '250px' }}>
      {bets.map((betSuggestion: any) => (
        <div key={betSuggestion.groupId} className="bet-item">
          {betSuggestion.items.length > 0 && <h2 className="mb-5 bet-item-title">{betSuggestion.items[0].title}</h2>}
          {betSuggestion.items.map((item: any, index: number) => (
            <BetItem key={index} item={item} />
          ))}

          {(() => {
            const IDs: Array<number> = [];
            betSuggestion.items.forEach((item: any) => {
              IDs.push(item.id);
            });
            return loading ? (
              <LoadingSpinner />
            ) : (
              <HoldToConfirmButton onSubmit={() => placeBet(IDs)}>
                Total: <span className="text-light-green">{currencyFormat(betSuggestion.total)}</span>
              </HoldToConfirmButton>
            );
          })()}
        </div>
      ))}
    </SwipeableViews>
  );
};

export default BetList;
