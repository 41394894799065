import React from 'react';
import { BalanceInterface } from '../interfaces';

interface BalanceInfoProps {
  balance?: BalanceInterface;
}

export const currencyFormat = function (num: any) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const BalanceInfo: React.FC<BalanceInfoProps> = ({ balance }) => {
  if (!balance) return null;

  return (
    <div className="balance">
      <p>{currencyFormat(balance?.availableBalance?.amount) ?? 'N/A'}</p>
    </div>
  );
};

export default BalanceInfo;
