import React from 'react';
import { Link } from 'react-router-dom';

const BottomButtons = () => {
  return (
    <div className="bottom-buttons-container">
      <Link to="/" className="border-emerald-900 border-solid border-2 text-emerald-300 font-bold squared p-1 hover:border-white hover:text-white">
        Live Betting
      </Link>
      <Link
        to="/my-bets"
        className="border-emerald-900 border-solid border-2 text-emerald-300 font-bold squared p-2 hover:border-white hover:text-white">
        My Bets
      </Link>
      <Link
        to="/fair-odds"
        className="border-emerald-900 border-solid border-2 text-emerald-300 font-bold squared p-2 hover:border-white hover:text-white">
        Fair Odds
      </Link>
      <Link
        to="/my-account"
        className="border-emerald-900 border-solid border-2 text-emerald-300 font-bold squared p-2 hover:border-white hover:text-white">
        My Account
      </Link>
    </div>
  );
};

export default BottomButtons;
