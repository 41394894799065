import useBets from '../hooks/useBets';
import LoadingSpinner from '../components/LoadingSpinner';

const MyBets = () => {
  const { placedBets, loading } = useBets();

  return (
    <div>
      <h1 className="text-white mt-20">Latest Waigr Bets with Nyra</h1>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <ul>
          {placedBets.map((bet) => (
            <li key={bet.id}>
              <div className="text-white mb-4 mt-4">
                <strong className="text-emerald-300">Ticket#:</strong> {bet.ticketSerialNumber}
                <br />
                <strong>{bet.suggestedBet.race.title}</strong> <br /> ${bet.suggestedBet.totalBetAmount} - {bet.createdAt.userFriendlyDate}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MyBets;
