import React, { useState } from 'react';
import './App.css';
import LoginWidget from './components/LoginWidget';
import BottomButtons from './components/BottomButton';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LiveBetting from './pages/LiveBetting';
import MyBets from './pages/MyBets';
import MyAccount from './pages/MyAccount';
import FairOdds from './components/FairOdds';
import BalanceInfo from './components/BalanceInfo';
import useBets from './hooks/useBets';

const App: React.FC = () => {
  const isSessionTokenInStorage = () => {
    const token = localStorage.getItem('sessionToken');
    if (token && token?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const [loggedIn, setLoggedIn] = useState(isSessionTokenInStorage);
  const { balance } = useBets();

  const handleLogin = () => {
    setLoggedIn(true);
  };

  return (
    <>
      {loggedIn ? (
        <Router>
          <div className="app-container">
            <div className="p-4 max-w-screen-lg flex items-center">
              <img src="logo512.png" alt="Logo" className="w-16 h-16 mr-4" />
              <div className="ml-auto">
                <BalanceInfo balance={balance} />
              </div>
            </div>
            <Routes>
              <Route path="/" element={<LiveBetting />} />
              <Route path="/my-bets" element={<MyBets />} />
              <Route path="/my-account" element={<MyAccount />} />
              <Route path="/fair-odds" element={<FairOdds />} />
            </Routes>
            <BottomButtons />
          </div>
        </Router>
      ) : (
        <LoginWidget onLogin={handleLogin} />
      )}
    </>
  );
};

export default App;
