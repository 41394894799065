import { useState, useEffect, useCallback, useRef } from 'react';
import { axiosInstance as axios } from '../services/axiosInstance';
import { BetSuggestion, Race, BalanceInterface } from '../interfaces';

const useBets = () => {
  const [race, setRace] = useState<Race | undefined>(undefined);
  const [balance, setBalance] = useState<BalanceInterface | undefined>(undefined);
  const [bets, setBets] = useState<BetSuggestion[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [placedBets, setPlacedBets] = useState<any[]>([]);
  const [username, setUsername] = useState<string>('');

  // Ref to track if the data has already been fetched
  const hasFetchedData = useRef(false);

  const fetchBets = useCallback(async () => {
    try {
      const response = await axios.get('api/v2/get-suggested-bets');
      setBets(response.data.data.bets);
      setRace(response.data.data.race);
      setUsername(response.data.username);
    } catch (error) {
      console.error('Error fetching bets:', error);
    }
  }, []);

  const getBalance = useCallback(async () => {
    try {
      const response = await axios.get('api/get-balance');
      setBalance(response.data);
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  }, []);

  const getMyBets = useCallback(async () => {
    try {
      const response = await axios.get('api/placed-bets');
      setPlacedBets(response.data.data);
    } catch (error) {
      console.error('Error fetching placed bets:', error);
    }
  }, []);

  const placeBet = async (betIDs: Array<number>) => {
    setLoading(true);
    try {
      await axios.post(
        'api/v2/place-bet',
        JSON.stringify({
          suggested_bet_id: betIDs,
        })
      );
      alert('YOU ARE DOWN!');
      await Promise.all([getBalance(), fetchBets()]);
    } catch (error) {
      console.error('Error placing bet:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasFetchedData.current) {
      const fetchAllData = async () => {
        setLoading(true);
        await Promise.all([getBalance(), fetchBets(), getMyBets()]);
        setLoading(false);
      };

      fetchAllData();
      hasFetchedData.current = true; // Mark data as fetched to prevent future redundant calls
    }
  }, [fetchBets, getBalance, getMyBets]);

  return {
    race,
    bets,
    loading,
    fetchBets,
    placeBet,
    balance,
    getBalance,
    getMyBets,
    placedBets,
    username,
  };
};

export default useBets;
