import React, { useState } from 'react';
import { BetItemProps } from '../interfaces';
import { FaInfoCircle } from 'react-icons/fa';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import Alert from './Alert';

const BetItem: React.FC<BetItemProps> = ({ item }: any) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  return (
    <>
      <div className="flex text-left">
        <div className="w-5/6 p-2">{item.horseSelection}</div>

        <div className="w-1/6 p-2">
          <div id={`#tooltip${item.id}`} onClick={handleOpen}>
            <FaInfoCircle style={{ fontSize: '24px', marginLeft: '5px', cursor: 'pointer', display: 'inline' }} />
          </div>
        </div>
      </div>

      <Alert title={item.info.title} description={item.info.description} isOpen={open} handleClose={handleOpen} />
    </>
  );
};

export default BetItem;
