import axios from 'axios';
import env from 'react-dotenv';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const sessionToken = localStorage.getItem('sessionToken');

    if (sessionToken) {
      config.headers['x-waigr-token'] = sessionToken;
    }

    config.headers['Content-Type'] = 'application/json';

    config.baseURL = env.REACT_APP_BACKEND_BASE;

    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

export { axiosInstance };
